import 'bootstrap/dist/css/bootstrap.min.css';
// Css Import
import './assets/scss/app.scss';

import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import ScrollToTop from './component/scrollToTop/ScrollToTop';
import ErrorPage from './pages/404';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Home from './pages/Home';
import OurOffice from './pages/OurOffice';
import ServiceDetails from './pages/ServiceDetails';
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';

const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<Home/>}/>
				<Route path={process.env.PUBLIC_URL + "/home"} element={<Home/>}/>

				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne />}/>
				<Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>

			

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice />}/>
				
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path='*' element={<ErrorPage />}/>
				

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
